<p-card header={{tableName}}>
    <p-table [columns]="cols" [value]="tableData" [tableStyle]="{'min-width': '20rem'}" [paginator]="true" [rows]="10"
        [rowsPerPageOptions]="[10, 20, 50]" [showCurrentPageReport]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        >
        <ng-template pTemplate="caption">
            @if(enableAdd){
            <div class="flex align-items-center justify-between">
                <button (click)="addAction()" pButton pRipple label="Add" icon="pi pi-plus"
                    class="p-button-help bg-vendblocblue text-white py-2 px-6 rounded-md"></button>
                <p-button icon="pi pi-refresh"></p-button>
            </div>
            }
        </ng-template>
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th style="max-width:0.5rem">#</th>
                <!-- [pSortableColumn]="col.field" <p-sortIcon [field]="col.field"></p-sortIcon> -->
                <th *ngFor="let col of columns">
                    @if(col.header !== ''){
                    {{ col.header }}
                    }
                </th>
                <th></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-row let-rowIndex="rowIndex">
            <tr>
                <td>{{rowIndex+1}}</td>
                <td *ngFor="let col of cols">
                    @if(col.header !== ''){
                    {{ row[col.field] }}
                    }
                </td>
                <td>
                    <p-toast></p-toast>
                    <p-confirmPopup></p-confirmPopup>
                    @if(enableShow){
                    <p-button icon="pi pi-external-link" (click)="showAction(rowIndex)" label="Show"></p-button>
                    }
                    @if(enableOpen){
                    <p-button icon="pi pi-external-link" (click)="openAction(row.id)" label="Open"></p-button>
                    }
                    @if(enableEdit){
                    <p-button icon="pi pi-pencil" (click)="editAction(rowIndex)" label="Edit"></p-button>
                    }
                    @if(enableDelete){
                    <p-button (click)="removeAction($event, row.id)" severity="danger" icon="pi pi-trash"></p-button>
                    }
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="summary">
            <div class="flex align-items-center justify-content-between">
                In total there are {{tableData ? tableData.length : 0 }} {{tableName}}.
            </div>
        </ng-template>
    </p-table>
</p-card>
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PrimeTableColumn } from '@models/prime-interface/prime-interface';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DialogModule } from 'primeng/dialog';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { ToolbarModule } from 'primeng/toolbar';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';

@Component({
  selector: 'app-table',
  standalone: true,
  imports: [ToastModule, ConfirmPopupModule, DialogModule,
    CardModule, ButtonModule, ToolbarModule, TableModule, CommonModule, IconFieldModule, InputIconModule],
  templateUrl: './table.component.html',
  styleUrl: './table.component.css'
})
export class TableComponent {
  @Input() tableData: object[] = [];
  @Input() cols: PrimeTableColumn[] = [];
  @Input() tableName: string = '';
  @Input() enableAdd: boolean = false;
  @Input() enableEdit: boolean = false;
  @Input() enableOpen: boolean = false;
  @Input() enableDelete: boolean = false;
  @Input() enableShow: boolean = false;
  @Output() addButtonPressed = new EventEmitter<boolean>();
  @Output() showButtonPressed = new EventEmitter<number>();
  @Output() openButtonPressed = new EventEmitter<number>();
  @Output() editButtonPressed = new EventEmitter<number>();
  @Output() removeButtonPressed = new EventEmitter<{ event: Event, id: number; }>();


  showAction(index: number) {
    this.showButtonPressed.emit(index);
  }

  editAction(index: number) {
    this.editButtonPressed.emit(index);
  }

  openAction(id: number) {
    this.openButtonPressed.emit(id);
  }


  addAction() {
    this.addButtonPressed.emit(true);
  }

  removeAction(event: Event, id: number) {
    this.removeButtonPressed.emit({ event, id });
  }
}
